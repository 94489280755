import React from "react";
import Logo from "../../images/logo.png";
import { makeStyles, Paper, Grid, LinearProgress } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(6) + "px " + theme.spacing(1) + "px ",
    padding: theme.spacing(2),
    background: "#0099cc",
    position: "fixed",
    transform: "translate(-50%,-50%)",
    left: "50%",
    top: "40%",
    width: "30%",
    height: "30%",
    filter: "drop-shadow(0px 32px 64px rgba(0,0,0,0.10))",
  },
  progressBar: {
    marginTop: theme.spacing(5),
  },
}));

const LoadingScreen = () => {
  const classes = useStyles();
  return (
    <Grid container justify="center">
      <Grid item xs={12} sm={5} lg={3}>
        <Paper className={classes.paper}>
          <img
            style={{
              display: "block",
              width: 180,
              margin: "0 auto",
            }}
            alt="Avaliei Dashboard"
            src={Logo}
          />
          <LinearProgress className={classes.progressBar} color="primary" />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default LoadingScreen;
