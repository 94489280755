import React, { Suspense, lazy } from "react";
import LoadingScreen from "./LoadingScreen";
import AuthProvider from "./AuthProvider";

const Routes = lazy(() => import("./Routes"));

const App = () => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <AuthProvider>
        <Routes />
      </AuthProvider>
    </Suspense>
  );
};

export default App;
