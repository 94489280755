import React from "react";
import Lottie from "react-lottie-player";
import lottieJson from "../../animations/ErrorAlert.json";
import { cleanAndReload } from "../../lib/localStorageFunctions";

import "./css/errorPageStyle.css";

const ErrorPage = () => {
  return (
    <section className="errorPageContainer">
      <section className="errorPageAnimationContainer">
        <div className="animationDiv">
          <Lottie
            loop
            animationData={lottieJson}
            play
            style={{ width: 200, height: 200 }}
          />
        </div>
      </section>
      <section className="errorPageTextContainer">
        <section className="errorPageText">
          <h2>Um erro inesperado aconteceu</h2>
          <br />
          <h1>
            Pedimos desculpas pelo incoveniente e já estamos trabalhando
            <br /> para que esse problema não aconteça novamente.
          </h1>
          <br />
          <h1>Clicar no botão abaixo pode resolver o problema</h1>
        </section>
      </section>
      <button className="errorPageButton" onClick={() => cleanAndReload()}>
        Recarregar
      </button>
    </section>
  );
};

export default ErrorPage;
