import {
  TOGGLE_WORKER_SHOULD_REFRESH,
  TOGGLE_WORKER_LOADING,
} from "../actions/worker";
import genObj from "../../lib/genObj";

const initialState = {
  shouldRefresh: false,
  loading: true,
};

const worker = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_WORKER_SHOULD_REFRESH:
      return genObj({ ...state, shouldRefresh: action.shouldRefresh });
    case TOGGLE_WORKER_LOADING:
      return genObj({ ...state, loading: action.loading });
    default:
      return state;
  }
};
export default worker;
