export const TOGGLE_USER_SHOULD_REFRESH =
  "TOGGLE_USER_SHOULD_REFRESH";
export const TOGGLE_USER_LOADING = "TOGGLE_USER_LOADING";

/**
 * It will update redux state to recieved parameter
 * @param {boolean} shouldRefresh
 */
export const setShouldRefresh = (shouldRefresh) => ({
  type: TOGGLE_USER_SHOULD_REFRESH,
  shouldRefresh,
});

/**
 * It will update redux state to recieved parameter
 * @param {true} loading
 */
export const setLoading = (loading) => ({
  type: TOGGLE_USER_LOADING,
  loading,
});
