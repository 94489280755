import React from "react";
import ReactDOM from "react-dom";
import App from "./components/main/App";
import reportWebVitals from "./reportWebVitals";
import CssBaseline from "@material-ui/core/CssBaseline";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { Provider } from "react-redux";
import configureStore from "./redux/store";
import Bugsnag from "./configs/bugsnag";
import ErrorPage from "./components/main/ErrorPage";

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#0099cc",
    },
    secondary: {
      main: "#f4f7f8",
    },
  },
});

ReactDOM.render(
  <>
    <CssBaseline />
    <ErrorBoundary FallbackComponent={ErrorPage}>
      <ThemeProvider theme={theme}>
        <Provider store={configureStore()}>
          <App />
        </Provider>
      </ThemeProvider>
    </ErrorBoundary>
  </>,
  document.getElementById("root")
);

reportWebVitals();
