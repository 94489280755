import {
  storeLocalUser,
  loadLocalUser,
  cleanLocalUser,
} from "../lib/localStorageFunctions";
import bugsnag from "./bugsnag";

const login = (username, password) =>
  fetch(process.env.REACT_APP_PARSE_URL + "/login", {
    headers: {
      "X-Parse-Application-Id": process.env.REACT_APP_PARSE_ID,
      "X-Parse-Rest-Api-Key": process.env.REACT_APP_REST_API_KEY,
    },
    method: "POST",
    body: JSON.stringify({ username, password }),
  }).then((r) => {
    if (r.status === 200) return r.json();
    throw new Error("Nome de usuário ou senha inválidos");
  });

const getRoles = (token, userId) =>
  fetch(
    process.env.REACT_APP_PARSE_URL +
      '/classes/_Role?where={"users":{"__type":"Pointer","className":"_User","objectId":"' +
      userId +
      '"}}',
    {
      headers: {
        "X-Parse-Application-Id": process.env.REACT_APP_PARSE_ID,
        "X-Parse-REST-API-Key": process.env.REACT_APP_REST_API_KEY,
        "X-Parse-Session-Token": token,
      },
    }
  ).then((r) => r.json());

const checkValidToken = (token) =>
  fetch(process.env.REACT_APP_PARSE_URL + "/sessions/me", {
    headers: {
      "X-Parse-Application-Id": process.env.REACT_APP_PARSE_ID,
      "X-Parse-Rest-Api-Key": process.env.REACT_APP_REST_API_KEY,
      "X-Parse-Session-Token": token,
    },
  })
    .then((r) => {
      if (r.status !== 200) throw new Error("Invalid Session token");
      return r.json();
    })
    .then((r) =>
      fetch(process.env.REACT_APP_PARSE_URL + "/users/" + r.user.objectId, {
        headers: {
          "X-Parse-Application-Id": process.env.REACT_APP_PARSE_ID,
          "X-Parse-Rest-Api-Key": process.env.REACT_APP_REST_API_KEY,
          "X-Parse-Session-Token": token,
        },
      })
    )
    .then((r) => {
      if (r.status !== 200) throw new Error("Invalid user info");
      return r.json();
    });

function User() {
  this.loggedIn = false;
  this.objectId = "";
  this.username = "";
  this.token = "";
  this.accessLevel = [];
  this.stores = [];

  this.is = (role) => {
    if (Array.isArray(role))
      for (var r of role) {
        if (this.accessLevel.includes(r)) return true;
      }
    else return this.accessLevel.includes(r);
    return false;
  };

  this.isModerator = () => this.accessLevel.length > 0;

  this.becomeUser = async ({
    objectId,
    username,
    token,
    accessLevel,
    stores,
  }) => {
    storeLocalUser({ objectId, username, token, accessLevel, stores });
    this.objectId = objectId;
    this.username = username;
    this.token = token;
    this.accessLevel = accessLevel;
    this.stores = stores;
    this.loggedIn = true;

    bugsnag.addMetadata("user", { name: username });

    return true;
  };

  this.login = (username, password) =>
    login(username, password).then((user) =>
      getRoles(user.sessionToken, user.objectId).then(({ results }) =>
        this.becomeUser({
          objectId: user.objectId,
          username: user.username,
          token: user.sessionToken,
          accessLevel: results.map((role) => role.name),
          stores: user.stores,
        })
      )
    );

  this.init = async () => {
    const userData = loadLocalUser();
    if (userData) {
      return checkValidToken(userData.token)
        .then((r) => this.becomeUser({ ...userData, ...r }))
        .catch(() => false);
    } else return false;
  };

  this.logout = async () => {
    this.becomeUser({
      objectId: "",
      username: "",
      token: "",
      accessLevel: [],
      stores: [],
    });
    cleanLocalUser();
    window.location.reload();
    return true;
  };
}

const user = new User();

export default user;
