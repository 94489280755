export const TOGGLE_WORKER_SHOULD_REFRESH =
  "TOGGLE_WORKER_SHOULD_REFRESH";
export const TOGGLE_WORKER_LOADING = "TOGGLE_WORKER_LOADING";

/**
 * It will update redux state to recieved parameter
 * @param {boolean} shouldRefresh
 */
export const setShouldRefresh = (shouldRefresh) => ({
  type: TOGGLE_WORKER_SHOULD_REFRESH,
  shouldRefresh,
});

/**
 * It will update redux state to recieved parameter
 * @param {true} loading
 */
export const setLoading = (loading) => ({
  type: TOGGLE_WORKER_LOADING,
  loading,
});
