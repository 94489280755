import { createStore, compose } from "redux";
import reducers from "./reducers";

const composeEnhancers =
  (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      trace: true,
      traceLimit: 25,
    })) ||
  compose;

const configureStore = () => {
  const store = createStore(reducers, composeEnhancers());

  return store;
};

export default configureStore;
